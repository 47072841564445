/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/custom.scss
 */
 .modal {
  font-size: 16px;
  background-color: #ffffff;
  width: 150%;
  text-align: center;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  color: black;
}
.modal > .content {
  width: 100%;
  padding: 1px 5px;
  color: black;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close-popup {
  color: black;
  width: 2% !important;
 
}

#locationtext {
  color: #ffffff;
}

#locationsearch {
  color: #ffffff;
}

      
#cityList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction:column;
  max-height:calc((40px)*72);
}

.list-group li {
  color: #3199d5;
  margin-right: 10px;
  width: 285px;
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  line-height: 20px;
  padding-bottom: 10px;
}

.list-group li:hover {
background-color: #3199d5;
color:#ffffff;
}

.callingNumber{
  color: #F13956;
}
.callingNumber:hover{
  color:#ffffff;
}

@media only screen and (min-device-width: 767px) and (max-device-width: 1223px) {
  #cityList {
    max-height:calc((40px)*144);
  }

}

@media only screen and (min-device-width: 0px) and (max-device-width: 767px) {
  #cityList {
    max-height:none;
    font-size: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

}
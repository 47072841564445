// Misc.
    $misc: (
        z-index-base:   10000
    );

// Duration.
    $duration: (
        navPanel: 0.5s
    );

// Size.
    $size: (
        navPanel: 275px,
        radius: 5px
    );

// Font.
    $font: (
    );

// Palette.
    $palette: (
        bg: #f7f7f7,
        fg: #474747,
        fg-bold: #4c4c4c,
        fg-light: #999,
        border: #e0e0e0,

        accent: (
            bg: #3199D5,
            fg: #fff
        )
    );
